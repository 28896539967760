@import "../config";
@import "../mixins/mixins-base";

.share {
    
    margin: 40px 0 40px;

    @include breakpoint(md) {
       margin: 40px 0 40px 20px;
    }

    @include breakpoint(sm) {
       margin: 0 0 40px;
    }

    .share-btn {
        @include font(bold);
        font-size: _em(14);
        text-transform: uppercase;
        color: #000000;
        text-decoration: none;
        position: relative;
        margin-right: 42px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        vertical-align: middle;

        &::after {
            content: "";
            background: url('../../../assets/images/icon-share.svg') no-repeat top left;
            display: block;
            width: 24px;
            height: 21px;
            position: absolute;
            right: -32px;
            top: 50%;
            transform: translateY(-50%);
        }
        
        &.--is-open::after {
            display:none;
        }
    }

    .share-social-buttons {
        display: inline-block;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: transform 0.3s ease-out;
        transform: translateX(-10px);

        &.--is-open {
            opacity: 1;
            pointer-events: auto;
            transform: translateX(0);
        }
    }
}