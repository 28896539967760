.share {
  margin: 40px 0;
}

@media (width <= 991px) {
  .share {
    margin: 40px 0 40px 20px;
  }
}

@media (width <= 768px) {
  .share {
    margin: 0 0 40px;
  }
}

.share .share-btn {
  text-transform: uppercase;
  color: #000;
  vertical-align: middle;
  height: 40px;
  margin-right: 42px;
  font-family: Eina Bold, sans-serif;
  font-size: .875em;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.share .share-btn:after {
  content: "";
  background: url("../../../assets/images/icon-share.svg") no-repeat;
  width: 24px;
  height: 21px;
  display: block;
  position: absolute;
  top: 50%;
  right: -32px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.share .share-btn.--is-open:after {
  display: none;
}

.share .share-social-buttons {
  opacity: 0;
  pointer-events: none;
  transition: -webkit-transform .3s ease-out, transform .3s ease-out;
  display: inline-block;
  position: absolute;
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}

.share .share-social-buttons.--is-open {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
/*# sourceMappingURL=share.css.map */
